<template>
  <div class="orderInformation" v-if="logData != ''">
    <Header :title="title" theme="light" />
    <div class="zone__main">
      <div class="wrap__content">
        <div v-if="countdown['orderId']">
          <CountDown :minute="minute" :sec="sec" />
        </div>
        <div class="content_1">
          <div>
            <span>订单金额: </span><span>¥ {{ logData.orderAmount }}</span>
          </div>
          <div>{{ logData.statusDesc }}</div>
        </div>
        <div class="paymentTypes">
          <div v-if="data.channelName == '银行卡'">
            <div class="box__img">
              <img src="../../assets/images/public/ic_order_bank.webp" alt="" />
            </div>
            <div class="">银行卡</div>
          </div>
          <div v-else-if="data.channelName == '支付宝'">
            <div class="box__img">
              <img src="../../assets/images/public/ic_order_alipay.webp" alt="" />
            </div>
            <div class="">支付宝</div>
          </div>
          <div v-else-if="data.channelName == '微信支付'">
            <div class="box__img">
              <img src="../../assets/images/public/ic_order_wechat.webp" alt="" />
            </div>
            <div class="">微信</div>
          </div>
          <div>
            <div :class="bankShow == false ? 'rotateZ__180' : 'rotateZ__0'" class="box__img" @click="bankShow = !bankShow">
              <img src="../../assets/images/public/ic_order_up.webp" alt="" />
            </div>
          </div>
        </div>
        <div v-if="bankShow == true" class="bankShow">
          <div v-for="(item, i) in copyBuySubmitData.traderAccounts" :key="i" @click="changeBank(item)">
            <div class="box__img">
              <img v-if="item.channelName == '银行卡' && item.channelName != data.channelName" src="../../assets/images/public/ic_order_bank.webp" alt="" />
              <img v-else-if="item.channelName == '支付宝' && item.channelName != data.channelName" src="../../assets/images/public/ic_order_alipay.webp" alt="" />
              <img v-else-if="item.channelName == '微信支付' && item.channelName != data.channelName" src="../../assets/images/public/ic_order_wechat.webp" alt="" />
            </div>
            <div class="">
              <span v-if="item.channelName == '银行卡' && item.channelName != data.channelName">银行卡</span>
              <span v-else-if="item.channelName == '支付宝' && item.channelName != data.channelName">支付宝</span>
              <span v-else-if="item.channelName == '微信支付' && item.channelName != data.channelName">微信</span>
            </div>
          </div>
        </div>
        <div class="content_2">
          <div class="mb_8px">
            收款方式 : <span>{{ data.channelName }}</span>
          </div>
          <div class="mb_8px">
            收款用户 : <span>{{ data.accountName }}</span>
          </div>
          <div class="flex-sb mb_2em">
            <div>
              收款帐号 : <span>{{ data.account }}</span>
            </div>
            <div v-clipboard:copy="data.account" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
              [复制]
            </div>
          </div>
          <div class="flex-sb mb_8px">
            <div>
              卖家名称 : <span>{{ data.seller }}</span>
            </div>
            <div class="save" v-if="isTraderRelations != true" @click="postTraderRelations({ traderId: logData['traderId'] })">
              [收藏]
            </div>
          </div>
          <div class="mb_8px">
            订单金额 : <span>{{ data.amount }}</span>
          </div>
          <div class="flex-sb">
            <div>
              订单编号 : <span>{{ data.orderNo }}</span>
            </div>
            <div v-clipboard:copy="data.orderNo" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
              [复制]
            </div>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <div
          @click="
            $router.push({
              path: `/message/${encodeURIComponent(logData.traderName)}/C2Ctrader_im_${logData.traderId}/trader_im_${logData.traderId}`,
            })
          "
        >
          <img src="../../assets/images/public/ic_payment_information.png" alt="" />
        </div>
        <div @click="payPhone(logData.userMobile)">
          <img src="../../assets/images/public/ic_payment_phone.png" alt="" />
        </div>
        <div
          @click="
            $router.push({
              name: 'cancelOrder',
              params: { orderNo: data.orderNo },
            })
          "
        >
          取消交易
        </div>
        <div @click="checkPayMoney()">我已付款</div>
      </div>
    </div>
    <!-- 確認是否付款彈窗 -->
    <div class="checkPayMoneyPopup" v-if="checkPopup == true">
      <div class="container__main">
        <div class="title">重要提示</div>
        <div class="content">
          请确认您是否向对方转帐，恶意点击将会对您进行封号处理
        </div>
        <div class="box__btn">
          <div @click="checkPopup = false">取消</div>
          <div @click="surePayMoney()">我已付款</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import CountDown from "@/components/CountDown.vue";
import { mapState, mapActions } from "vuex";
import { Message } from "element-ui";
import Cookies from "js-cookie";
export default {
  name: "orderInformation",
  components: {
    Header,
    CountDown,
  },
  data() {
    return {
      data: {},
      title: "请向卖家付款",
      timeCount: 0,
      timePush: 0,
      totalSec: 0,
      sec: 0,
      minute: 0,
      bankShow: false,
      isTraderRelations: false,
      checkPopup: false,
      copyBuySubmitData: [],
    };
  },
  computed: {
    ...mapState("orders", ["buySubmitData", "countdown", "logData"]),
    ...mapState("trader", ["traderRelationsData"]),
    ...mapState("buy", ["buyFastTradersData"]),
    ...mapState("message", ["msgList", "isTimLogin"]),
  },
  watch: {
    totalSec(e) {
      const self = this;
      if (e == 0) {
        clearInterval(self.timeCount);
        self.timePush = setInterval(() => {
          self.getLogId(this.$route.query.orderId);
        }, 1000);
      }
    },
    traderRelationsData() {
      this.findTraderRelations();
    },
    countdown(e) {
      this.totalSec = e["timeLeft"];
    },
    ["logData.status"](e) {
      if (e != 0) {
        clearInterval(this.timePush);
        this.$router.push({
          path: "/orderInfo",
          query: {
            orderNo: this.logData.orderNo,
            orderId: this.logData.id,
          },
        });
      }
    },
    logData(log) {
      // 另開分頁資料遺失處理
      if (this.copyBuySubmitData == undefined || this.copyBuySubmitData.length == 0) {
        this.data = {
          amount: log.orderAmount,
          orderNo: log.orderNo,
          seller: log.traderName,
          account: log.traderAccounts[0].account,
          channelCode: log.traderAccounts[0].channelCode,
          accountName: log.traderAccounts[0].accountName,
          channelName: log.traderAccounts[0].channelName,
          traderAccountId: log.traderAccounts[0].id,
          orderId: this.$route.query.orderId,
        };
        this.getCountdown({ orderNo: log.orderNo }).then((res) => {
          if (res.code == "fail") {
            this.$router.push({
              path: "/orderInfo",
              query: {
                orderNo: this.logData.orderNo,
              },
            });
          }
        });
        setTimeout(this.time(), 1500);
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    ...mapActions("trader", ["getTraderRelations", "postTraderRelations"]),
    ...mapActions("orders", ["getCountdown", "putBuySellerSubmit", "getLogId"]),
    ...mapActions("message", ["getMsgList"]),
    init() {
      const self = this;
      if (this.isTimLogin) this.getMsgList();
      this.getLogId(this.$route.query.orderId);
      if (!Cookies.get("buySubmitData")) {
        Cookies.set("buySubmitData", self.buySubmitData != "" ? JSON.stringify(this.buySubmitData) : JSON.stringify(this.buyFastTradersData));
      }

      self.copyBuySubmitData = JSON.parse(Cookies.get("buySubmitData")) ? JSON.parse(Cookies.get("buySubmitData")) : this.buySubmitData != "" ? this.buySubmitData : this.buyFastTradersData;
      const data = {
        orderNo: this.$route.query.orderNo,
      };
      self.data = {
        amount: self.copyBuySubmitData.amount,
        orderNo: self.copyBuySubmitData.orderNo,
        seller: self.copyBuySubmitData.seller,
        account: self.copyBuySubmitData.traderAccounts[0].account,
        channelCode: self.copyBuySubmitData.traderAccounts[0].channelCode,
        accountName: self.copyBuySubmitData.traderAccounts[0].accountName,
        channelName: self.copyBuySubmitData.traderAccounts[0].channelName,
        traderAccountId: self.copyBuySubmitData.traderAccounts[0].id,
      };
      this.getTraderRelations({});
      this.getCountdown(data).then((res) => {
        if (res.code == "fail") {
          this.$router.push({
            path: "/orderInfo",
            query: {
              orderNo: this.logData.orderNo,
              orderId: this.logData.id,
            },
          });
        }
      });
      setTimeout(this.time(), 1500);
    },
    payPhone(num) {
      location.href = `tel:${num}`;
    },
    surePayMoney() {
      Cookies.remove("buySubmitData");
      let data = {
        orderNo: this.data.orderNo,
        traderAccountId: this.data.traderAccountId,
        amount: this.data.amount,
        orderId: this.logData.id,
      };
      this.putBuySellerSubmit(data);
      this.$router.push({
        path: "/sellerConfirmation",
        query: {
          orderNo: this.data.orderNo,
          traderAccountId: this.data.traderAccountId,
          amount: this.data.amount,
          orderId: this.logData.id,
        },
      });
    },
    checkPayMoney() {
      this.checkPopup = true;
    },
    changeBank(data) {
      const self = this;
      self.data = {
        amount: self.copyBuySubmitData.amount,
        orderNo: self.copyBuySubmitData.orderNo,
        seller: self.copyBuySubmitData.seller,
        account: data.account,
        channelCode: data.channelCode,
        accountName: data.accountName,
        channelName: data.channelName,
        traderAccountId: data.id,
      };
      self.bankShow = false;
    },

    findTraderRelations() {
      const self = this;
      self.traderRelationsData.forEach((e) => {
        if (e.traderId == self.logData.traderId) {
          self.isTraderRelations = true;
        }
      });
    },
    time() {
      const self = this;
      self.totalSec = this.countdown["timeLeft"];
      self.timeCount = setInterval(function() {
        self.totalSec = self.totalSec - 1;
        self.minute = parseInt(Math.floor(self.totalSec / 60));
        self.sec = parseInt(Math.floor(self.totalSec % 60));
      }, 1000);
    },
    handleCopySuccess() {
      Message({
        message: "复制成功",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    handleCopyError() {
      Message({
        message: "复制失败",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
  },
  destroyed() {
    clearInterval(this.timeCount);
    clearInterval(this.timePush);
  },
};
</script>
<style lang="scss" scoped>
.orderInformation {
  width: 100%;
  height: calc(100vh - 52px);
  overflow-y: scroll;

  .zone__main {
    width: 100%;

    .wrap__content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      @media (min-width: 480px) {
        justify-content: center;
      }
      .content_1 {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-weight: bold;
        padding-top: 1.5rem;

        div:nth-child(1) {
          span:nth-child(1) {
            color: var(--gray);
          }

          span:nth-child(2) {
            color: var(--orange);
          }
        }

        div:nth-child(2) {
          color: var(--orange);
        }
      }

      .bankShow {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: bold;
        padding-top: 0.6rem;
        flex-wrap: wrap;

        & > div {
          width: 100%;
        }

        div {
          display: flex;
          align-items: center;

          .box__img {
            width: 2rem;
            font-size: 0;
            margin-right: 0.5rem;

            img {
              width: 100%;
            }
          }
        }
      }

      .paymentTypes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        padding-top: 1.5rem;

        div:nth-child(1) {
          display: flex;
          align-items: center;

          .box__img {
            width: 1.5rem;
            font-size: 0;
            margin-right: 0.5rem;

            img {
              width: 100%;
            }
          }
        }

        div:nth-child(2) {
          .box__img {
            width: 1rem;
            font-size: 0;

            img {
              width: 100%;
            }
          }
        }
      }

      .content_2 {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding-top: 1.5rem;
        flex-wrap: wrap;
        font-size: 0.8rem;

        & > div {
          width: 100%;
          text-align: left;

          color: var(--gray);

          div {
            color: var(--gray);
          }

          span {
            color: var(--black);
          }

          .save {
            color: var(--orange);
          }
        }
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div:nth-child(1),
  div:nth-child(2) {
    width: 10%;
    margin-left: 1rem;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  div:nth-child(3),
  div:nth-child(4) {
    width: 40%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 0.8rem;
  }

  div:nth-child(3) {
    background-color: var(--light-gray);
    color: var(--gray);
    margin-left: 1rem;
  }

  div:nth-child(4) {
    background-color: var(--orange);
    color: var(--white);
  }
}

.checkPayMoneyPopup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .container__main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: aliceblue;
    width: 80%;
    height: fit-content;
    padding: 2rem 1rem;
    border-radius: 15px;
    // max-width: 414px;
    @media (min-width: 480px) {
      max-width: unset;
    }
    .title {
      font-weight: bold;
      color: var(--orange);
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    .content {
      text-align: left;
      margin-bottom: 1.5rem;
    }

    .box__btn {
      display: flex;
      justify-content: space-around;

      div {
        width: 40%;
        padding: 0.8rem 0rem;
        color: aliceblue;
        border-radius: 20px;

        &:nth-child(1) {
          background-color: var(--light-black);
        }

        &:nth-child(2) {
          background-color: var(--orange);
        }
      }
    }
  }
}

.rotateZ__180 {
  transform: rotateZ(180deg);
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // left: 0;
  // left: 34%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // max-width: 414px;
  margin: 0 auto;
}
</style>
